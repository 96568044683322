import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./About.module.css";

import img_profile from "../assets/images/portrait_pro_small.jpg";

function About(props) {
	return (
		<div className={styles.content}>
			<div className={styles.presentation}>
				<img
					className={styles.portrait}
					src={img_profile}
					alt="About me"
				/>
				<p>
					Vidéaste indépendant, Lunographe est mon nom
					créatif. Après des études de multimédia et un parcours professionnel dans le
					digital (développeur, chef de projet et concepteur multimédia), j&#39;ai décidé de me consacrer davantage à
					l&#39;audiovisuel. Développant, notamment, une expertise dans la création de formats pour les réseaux sociaux.
				</p>
				<p>
					La création vidéo est une passion de longue
					date (adolescent, je réalise des petits
					court-métrages avec mon caméscope et suis
					projectionniste dans le ciné-club du lycée). Mes
					projets naissent avec l&#39;envie de raconter des
					histoires, en transmettant des émotions et du savoir, en stimulant l&#39;imagination et la réflexion.
					En apportant aussi ma touche de poésie, et parfois mon style surréaliste.
				</p>
				<p>
					Je vous invite à découvrir mes{" "}
					<Link to="/pro" className={styles.link}>réalisations professionnelles</Link> et mes{" "}
					<Link to="/art" className={styles.link}>créations artistiques</Link>.
					N'hésitez pas à me <Link to="/contact" className={styles.link}>contacter</Link> pour plus d'informations.
				</p>
				<div className={styles.poem}>
					<p>
						Si tous les rêves se nourrissaient de la Lune,
						<br />
						Et n&#39;en laissaient que des quartiers,
						<br />
						S&#39;il existait un appareil capable de la
						régénérer,
						<br />
						On l&#39;appellerait sûrement,
						<br />
						Lunographe
						<br />
					</p>
				</div>
			</div>
		</div>
	);
}

export default About;
