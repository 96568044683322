import React, { useState } from "react";
import useSmallScreenDetect from "../hooks/useSmallScreenDetect";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import VideoHeader from "../components/VideoHeader";
import ProjectsNav from "../components/ProjectsNav";
import Services from "../components/Services";

import AnimConf from "../conf/animation";

import styles from "./Pro.module.css";

function Pro(props) {
	const [navLoaded, setNavLoaded] = useState(false);
	const isSmallScreen = useSmallScreenDetect();	

	return (
		<div className={styles.content}>
			{!isSmallScreen ? (
				<VideoHeader vid="header-pro" />
			) : (
				<div className={styles["header-image"]} />
			)}
			<div className={styles["nav-section"]}>
				<ProjectsNav isPro={true} triggerLoaded={() => setNavLoaded(true)} />
			</div>
			{navLoaded && (
				<div className={styles["services-section"]}>
					<Transition
						timeout={1000}
						mountOnEnter
						unmountOnExit
						appear
						in={true}
						onEnter={
							node => {
								gsap.set(node, { opacity: 0, y: 10 });
								gsap.to(node, { duration: 0.7, opacity: 1, y: 0 });
							}
						}
					>
						<Services />
					</Transition>
				</div>
			)}
		</div>
	);
}

export default Pro;
