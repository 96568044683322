import React from "react";

import styles from "../components/VideoHeader.module.css";

function VideoHeader({ vid }) {
	return (
		<div className={styles.container}>
			<div id={`video-bg-${vid}`} className={styles["video-container"]}>
				<video autoPlay muted loop>
					<source src={`assets/videos/${vid}.webm`} type="video/webm" />
					<source src={`assets/videos/${vid}.mp4`} type="video/mp4" />
				</video>
			</div>
		</div>
	);
}

export default VideoHeader;
