import React, { useState, useRef } from "react";
import { gsap } from "gsap";

import VideoPlayer from "./VideoPlayer";

import styles from "./ProjectMedium.module.css";
import classNames from "classnames/bind";

function ProjectMedium(props) {
  const [isVertical, setIsVertical] = useState(props.isVertical || false);
  const project = props.project;
  const ref = useRef(null);

 	let cx = classNames.bind(styles);

  function triggerReady(p) {
    setIsVertical(p.isVertical);
    gsap.to(ref.current, {duration: 1, opacity: 1, delay: 0.3});
  }

  return (
    <div ref={ref} className={cx({container: true, vertical: isVertical})} style={{ opacity: 0 }}>
      <VideoPlayer vid={project.id} title={project.title} autoPlay={false} triggerReady={triggerReady} />
    </div>
  );
}

export default ProjectMedium;
