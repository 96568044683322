import React from "react";
import { Transition } from "react-transition-group";
import { gsap } from "gsap" ;

import styles from "./Footer.module.css";

import ContactBar from "./ContactBar";

function Footer(props) {
	const {location} = props;
	return (
		<footer className={styles.container}>
		{(location.pathname === "/" ||
				location.pathname === "/contact") && 
		    <Transition
			    timeout={1000}
			    appear
			    in={true}
	           	onEnter={
	              node => {
	                gsap.set(node, { opacity: 0 }); 
	                gsap.to(node, { duration: 0.7, y: -10, opacity: 1, delay: 1.3 });
	              }
	            }
               	onExit={
	              node => {
	                gsap.to(node, { duration: 0.5, y: 0, opacity: 0 });
	              }
	            }
			>
				<ContactBar />
			</Transition>
		}
		</footer>
	);
}

export default Footer;
