import React from "react";
import { HashRouter } from "react-router-dom";
import { render } from "react-snapshot";
import App from "./App";
import "./index.css";
//Polyfill
import 'whatwg-fetch';

render(
	<HashRouter>
		<App />
	</HashRouter>,
	document.getElementById("root")
);
