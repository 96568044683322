import React from "react";
import styles from "./ButtonRectangle.module.css";


function ButtonRectangle (props) {
	return (
		<button {...props} className={styles.button}>{props.children}</button>
	)
}

export default ButtonRectangle;