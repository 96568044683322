import React, { Component } from 'react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import "./VideoPlayer.css";

export default class VideoPlayer extends Component {

    constructor(props, context) {
        super(props, context);

        this.vid = props.vid;
        this.title = props.title;
        this.htmlEl = null;
        this.triggerReady = props.triggerReady;
        this.source = `https://www.lunographe.com/assets/videos/play/${this.vid}.mp4`;
        this.poster = `https://www.lunographe.com/assets/videos/play/pst/${this.vid}_pst.jpg`;

        this.state = {
            isReady: false,
            isPlaying: false
        }

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.htmlEl = this.player.manager.rootElement;
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = e => {
        if (this.state.isPlaying) {
            if (
                this.htmlEl.getBoundingClientRect().bottom < 0 ||
                this.htmlEl.getBoundingClientRect().top >
                    (window.innerHeight ||
                        document.documentElement.clientHeight)
            ) {
                this.pause();
            }
        }
    }

    handleStateChange(state, prevState) {
        if(!this.state.isReady && state.readyState > 0) {
            this.setState({ 
                isReady: true,
                player: state,
                isPlaying: !state.paused
            });
            this.onReady();
        } else {
            this.setState({
                player: state,
                isPlaying: !state.paused
            });
        }
    }

    onReady() {
        this.triggerReady({isVertical: this.player.videoHeight >= this.player.videoWidth});
    }

    play() {
        this.player.play();
    }

    pause() {
        this.player.pause();
    }

    render() {
        return (
            <div>
                <Player
                    poster={this.poster}
                    title={this.title}
                    ref={player => {
                        this.player = player;
                    }}
                    preload="metadata"
                >
                    <source src={this.source} />
                    <BigPlayButton position="center" />
                    <ControlBar />
                </Player>
            </div>
        )
    }
}