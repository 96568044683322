import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import styles from "./ButtonMoon.module.css";

function ButtonMoon(props) {
  const { in: show, label, index = 0, url } = props;
  return (
    <Link to={url}>
      <Transition
        timeout={1000}
        mountOnEnter
        unmountOnExit
        appear
        in={show}
        addEndListener={(node, done) => {
          gsap.to(node, {
            duration: 1,
            opacity: 1,
            delay: index * 0.25,
            onComplete: done,
          });
        }}
      >
        <button className={styles["button"]}>
          {label}
        </button>
      </Transition>
    </Link>
  );
}

export default ButtonMoon;
