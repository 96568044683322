import React, { useRef, useEffect } from "react";
import { gsap, Power2 } from "gsap";

import ProjectMedium from "./ProjectMedium";

import cx from "classnames";
import styles from "./ProjectDetails.module.css";

function ProjectDetails(props) {
  const {
    project,
    onClose,
    autoScroll
  } = props;
  const projectRef = useRef(null);

  const getDistinctionsEl = () => {
    return (
      <ul>
        {project.distinctions.map((distinction, i) => (
          <li key={i}>{distinction.label}</li>
        ))}
      </ul>
    );
  };

  const getRolesEl = () => {
    return (
      <ul>
        {project.roles.map((role, i) => (
          <li key={i}>{role.label}</li>
        ))}
      </ul>
    );
  };

/*
  const handleClose = e => {
    gsap.to(projectRef.current, {
      duration: 1,
      height: 0,
      ease: "Power2.out",
      onComplete: onClose
    });
    e.preventDefault();
  };
*/

  const scrollToComponent = () => {
    gsap.to(window, {
      duration : 1.2,
      scrollTo: projectRef.current,
      ease: "Power2.out"
    });
  };

  useEffect(() => {
    autoScroll && scrollToComponent();
  }, [autoScroll]);

  return (
    <section className={cx(styles.container, styles.show)} ref={projectRef}>
      <ProjectMedium project={project} />
      <section className={styles.details}>
        {project.link && (
          <p>
            <a className={styles["external-link"]} href={project.link}>
              {project.link}
            </a>
          </p>
        )}
        <p className={styles.description}>{project.description}</p>
        <div className={styles.flex}>
          <div className={styles.roles}>
            <p>
              <span className={styles["col-project.title"]}>Mon rôle</span>
            </p>
            {getRolesEl()}
          </div>
          {!!project.distinctions.length && (
            <div className={styles.distinctions}>
              <p>
                <span className={styles["col-project.title"]}>Distinctions</span>
              </p>
              {getDistinctionsEl()}
            </div>
          )}
          <div className={styles.credits}>
            <p>
              <span className={styles["col-project.title"]}>Autres crédits</span>
            </p>
            <p>{project.credits}</p>
          </div>
        </div>
      </section>
      {/*<button className={styles["btn-close"]} onClick={handleClose} />*/}
    </section>
  );
}

export default ProjectDetails;
