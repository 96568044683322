import React, { useState } from "react";
import useSmallScreenDetect from "../hooks/useSmallScreenDetect";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import VideoHeader from "../components/VideoHeader";
import ProjectsNav from "../components/ProjectsNav";

import AnimConf from "../conf/animation";

import styles from "./Art.module.css";

function Art(props) {

	const [navLoaded, setNavLoaded] = useState(false);

	const isSmallScreen = useSmallScreenDetect();

	return (
		<div className={styles.content}>
		{!isSmallScreen ? (
			<VideoHeader vid="header-art" />
		) : (
			<div className={styles["header-image"]} />
		)}
			<div className={styles["nav-section"]}>
				<ProjectsNav isPro={false} triggerLoaded={() => setNavLoaded(true)} />
			</div>
			{navLoaded && (
				<Transition
		        timeout={1000}
		        mountOnEnter
		        unmountOnExit
		        appear
		        in={navLoaded}
		        onEnter={node => gsap.set(node, { autoAlpha: 0, y: 10 })}
		        addEndListener={(node, done) => {
		          gsap.to(node, {
		            duration: 0.5,
		            autoAlpha: 1,
		            y: 0,
		            ease: "Power2.out",
		            onComplete: done
		          });
		        }}
		      >
				<div className={styles.footer}>
        			<section className={styles["more"]}>
        				<h1 className={styles.title}>Nouveau projet en développement : Nantes imaginaire !</h1>
        				Une série de récits visuels surréalistes à suivre sur le compte Instagram{" "}
						<a href="https://www.instagram.com/nantesimaginaire" className={styles.link}>@nantesimaginaire</a>
						<br /><br />
						<h1 className={styles.title}>Découvrez plus de créations...</h1>
						sur mes comptes <a href="https://www.instagram.com/lunographe" className={styles.link}>Instagram</a>,{" "}
						<a href="https://www.facebook.com/lunographe" className={styles.link}>Facebook</a>{" "}
						et <a href="https://www.youtube.com/lunographe" className={styles.link}>Youtube</a>.
					</section>
					<section className={styles["poem"]}>
						Si tous les rêves se nourrissaient de la Lune,<br />
						Et n&#39;en laissaient que des quartiers,<br />
						S&#39;il existait un appareil capable de la régénérer,<br />
						On l&#39;appellerait sûrement,<br />
						Lunographe.
		        	</section>
			    </div>
			    </Transition>
		    )}
		    <div>&nbsp;</div>
		</div>
	);
}

export default Art;