import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/svg/logo.svg";
import styles from "../components/Logo.module.css";

function Logo({ showName = true }) {
	return (
		<div className={styles.container}>
			<Link to="/">
				<img src={logo} className={styles.logo} alt="Lunographe" />
			</Link>
			{showName && <p className={styles["name"]}>Emmanuel Kodjo</p>}
		</div>
	);
}

export default Logo;
