import React from "react";

import styles from "./Contact.module.css";

function Contact(props) {
	return (		
		<div className={styles.content}>
			<section className={styles.details}>
        <p>
          Emmanuel Kodjo<br/>
          117, rue de charenton<br/>
          75012 Paris<br/>
          </p>
          <p>
            Tél : 06 20 91 40 97<br/>
          </p>
          <p>
            Email :<br/>
            <a href="mailto:contact@lunographe.com">contact@lunographe.com</a>
          </p>
			</section>
		</div>
	);
}

export default Contact;
