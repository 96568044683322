import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import Logo from "./Logo";
import { ReactComponent as BackButton } from "../assets/svg/back.svg";
import styles from "./Header.module.css";

function Header(props) {
  const navigate = useNavigate();
  const {location} = props;
  const menuInputRef = useRef(null);
  
  const handleMenuInput = () => {
    document.body.style.overflow = document.body.style.overflow ? "" : "hidden";
  };
  const handleBackButton = () => {
    navigate(-1);
  };

  const labels = ["Prestations vidéo", "Films & Art", "À propos", "Contact", "Mentions légales"];

  useEffect(() => {
    menuInputRef.current.checked = false;
    window.scrollTo(0, 0);
    document.body.style.overflow = "";
  },[location.pathname]);

  return (
    <header
      className={`${styles.container} ${
        location.pathname === "/" ? styles["home"] : ""
      }`}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      {location.pathname !== "/" && (
        <button className={styles["btn-back"]} onClick={handleBackButton}>
          <BackButton />
        </button>
      )}
      <nav role="navigation" className={styles.nav}>
        <div className={styles["hamburger-button"]}>
          <input type="checkbox" onClick={handleMenuInput} ref={menuInputRef} />
          <div className={styles["hamburger-icon"]}>
            <hr />
            <hr />
            <hr />
          </div>
          <div className={styles.wrapper}>
            <div className={styles["btn-close"]}></div>
            <ul>
              <li>
                {location.pathname !== "/pro" ? (
                  <Link to="/pro" className={styles.link}>
                    {labels[0]}
                  </Link>
                ) : (
                  labels[0]
                )}
              </li>
              <li>
                {location.pathname !== "/art" ? (
                  <Link to="/art" className={styles.link}>
                    {labels[1]}
                  </Link>
                ) : (
                  labels[1]
                )}
              </li>
              <li>
                {location.pathname !== "/about" ? (
                  <Link to="/about" className={styles.link}>
                    {labels[2]}
                  </Link>
                ) : (
                  labels[2]
                )}
              </li>
              <li>
                {location.pathname !== "/contact" ? (
                  <Link to="/contact" className={styles.link}>
                    {labels[3]}
                  </Link>
                ) : (
                  labels[3]
                )}
              </li>
              <li>
                {location.pathname !== "/legal" ? (
                  <Link to="/legal" className={styles.link}>
                    {labels[4]}
                  </Link>
                ) : (
                  labels[4]
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
