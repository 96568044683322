import { useState, useEffect } from "react";

function useSmallScreenDetect() {
	const detect = () => window.innerWidth <= 1200;
	const [isSmallScreen, setIsSmallScreen] = useState(detect());
	const handleResize = () => {
		setIsSmallScreen(detect());
	};
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return isSmallScreen;
}

export default useSmallScreenDetect;
