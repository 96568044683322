import React, { useEffect } from "react";

import ProjectMedium from "./ProjectMedium";

import styles from "./ProjectLite.module.css";
import classNames from "classnames/bind";

function ProjectLite(props) {
  const { project, in: show, index = 0 } = props;
  const getRolesEl = () => {
    return (
      <ul>
        {project.roles.map((role, i) => (
          <li key={i}>{role.label}</li>
        ))}
      </ul>
    );
  };

  let cx = classNames.bind(styles);

  return (
    <section className={cx({container: true, shift: !index })} style={{ opacity: 0 }}>
      <h1 className={styles.title}>{project.title}</h1>
      <ProjectMedium project={project} />
      <div className={styles.roles}>{getRolesEl()}</div>
      <p className={styles.credits}>{project.credits}</p>
    </section>
  );
}

export default ProjectLite;
