import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import AnimConf from "../conf/animation";

import styles from "./Home.module.css";

function Home(props) {
	return (
		<div className={styles.content}>
			<Transition
			    timeout={1000}
			    appear
			    in={true}
	           	onEnter={
	              node => {
	                gsap.set(node, { opacity: 0 }); 
	                gsap.to(node, { duration: 0.7, y: -10, opacity: 1, delay: 0.7 });
	              }
	            }
               	onExit={
	              node => {
	                gsap.to(node, { duration: 0.5, y: 0, opacity: 0 });
	              }
	            }
			>
				<div className={styles.nav}>
					<Link className={`${styles.link}`} to="/pro">
						Prestations vidéo
					</Link>
					<Link className={`${styles.link}`} to="/art">
						Films & Art
					</Link>
				</div>
			</Transition>
		</div>
	);
}

export default Home;
