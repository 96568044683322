import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Transition } from "react-transition-group";
import { TransitionGroup } from "react-transition-group";
import { gsap } from "gsap";
import Project from "../components/Project";
import ProjectLite from "../components/ProjectLite";
import MoonLoader from "../components/Loader";
import ButtonRectangle from "../components/ButtonRectangle";

import ApiConf from "../conf/backend";
import AnimConf from "../conf/animation";

import styles from "./Projects.module.css";

function Projects(props) {
  const [collection, setCollection] = useState([]);
  const [projects, setProjects] = useState([]);
  const [curPage, setCurPage] = useState(0);
  const [showPage, setShowPage] = useState(false);
  const pageRef = useRef(null);
  const limit = 2;

  let params = useParams();

  const getProjects = () => {
    const isSingle = projects.length === 1;
    return projects.map((project, index) => {
      if (project.type.id === 4 || project.type.id === 6)
        return (
          <Transition
            key={'Tr' + project.id}
            timeout={1000}
            mountOnEnter
            unmountOnExit
            appear
            in={true}
            onEnter={
              node => {
                gsap.set(node, { opacity: 0 }); 
                gsap.to(node, { duration : 1, opacity: 1 });
              }
            }
          >
            <ProjectLite project={project} key={project.id} index={index} />
          </Transition>
        );
      return (
        <Transition
          key={'Tr' + project.id}
          timeout={1000}
          mountOnEnter
          unmountOnExit
          appear
          in={true}
          onEnter={
            node => {
              gsap.set(node, { opacity: 0 }); 
              gsap.to(node, { duration : 1, opacity: 1 });
            }
          }
        >
          <Project
            project={project}
            key={project.id}
            index={index}
            isActive={isSingle}
            autoScroll={!isSingle}
          />
        </Transition>
      );
    });
  };

  const displayNext = () => {
    const start = curPage * limit, end = start + limit;
    const slice = collection.slice(start, end);
    setProjects(projects.concat(slice));
    setCurPage(curPage + 1);
  }

  useEffect(() => {
    const fetchProjects = params => {
      let route = "projects";
      if (params.id !== undefined) {
        route = `projects/${params.id}`;
      } else if (params.type !== undefined) {
        route = `projects/type/${params.type}`;
      }
      fetch(`${ApiConf.rootUrl}${route}`)
        .then(response => response.json())
        .then(collection => {
          setCollection(collection);
          collection.length > 0 && setProjects(collection.slice(0, limit));
          setCurPage(1);
          setShowPage(true); 
        })
        .catch(e => {
          console.log(e);
        });
    };

    fetchProjects(params);
    
    return () => {
      setShowPage(false);
    };
  }, []);

  return (
    <>
      <div className={styles.content} ref={pageRef}>
        <div className="projects">
          <TransitionGroup>{getProjects()}</TransitionGroup>
        </div>
        {(projects.length < collection.length) && <div className={styles.loadmore}><ButtonRectangle onClick={displayNext}>CHARGER PLUS</ButtonRectangle></div>}
      </div>
      {!projects.length && <MoonLoader />}
    </>
  );
}

export default Projects;
