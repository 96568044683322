import React from "react";
import { Link } from "react-router-dom";

import AnimConf from "../conf/animation";

import styles from "./MentionsLegales.module.css";

function MentionsLegales(props) {
	return (
		<div className={styles.content}>
			<section className={styles.details}>
	          	<p>
	            Site édité par<br/>
	            Emmanuel Kodjo<br/>
	            Micro-entrepreneur<br/>
	           	Code APE : 5911B <br/>
	           	SIRET : 45118909600022<br/>
	            <Link className={`${styles.link}`} to="/contact">Contact</Link><br/>
	            </p>
	            <p>
	            Site hébergé par<br/>
	            OVH SAS<br/>
	            2 rue Kellermann<br/>
	            59100 Roubaix
	            <br/>
	            France<br/>
	            </p>
			</section>
		</div>
	);
}

export default MentionsLegales;
