import React, { useState, useEffect, useRef } from "react";
import { TransitionGroup } from "react-transition-group";
//import { gsap } from "gsap";

import ApiConf from "../conf/backend";

import ButtonMoon from "./ButtonMoon";

import styles from "../components/ProjectsNav.module.css";

function ProjectsNav(props) {
  const [projectsTypes, setProjectsTypes] = useState([]);
  const { isPro=false, triggerLoaded } = props;
  const navRef = useRef(null);

  const getProjectsTypesEl = () => {
    return (
      <TransitionGroup>
        {projectsTypes.map((row, index) => (      
          <ButtonMoon key={row.id} label={row.label} index={index} url={`/projects/type/${row.id}`} />
        ))}
      </TransitionGroup>
    );
  };

  useEffect(() => {
    const fetchProjectsTypes = isPro => {
      const route = `projects/types?is_pro=${+isPro}`;
      return fetch(`${ApiConf.rootUrl}${route}`)
        .then(response => response.json())
        .then(types => {
          setProjectsTypes(types);
          setTimeout(triggerLoaded, 1000);
        })
        .catch(e => {
          console.log(e);
        });
    };
    fetchProjectsTypes(isPro);
  }, []);

  return (
    <div
      id="projects-nav"
      role="navigation"
      className={styles.container}
      ref={navRef}
    >
      {getProjectsTypesEl()}
    </div>
  );
}

export default ProjectsNav;
