import React from "react";
import {Link} from "react-router-dom";
import styles from "./Services.module.css";

function Services(props) {
    return (
      <div className={styles.container}>
        <section className={styles.pres1}>
          <h1 className={styles.title}>Vous êtes une PME ayant un objectif social, culturel ou environnemental ?</h1>
          Je vous aide à donner de la visibilité à votre activité sur le web et les réseaux sociaux. Il peut s'agir de :
          <ul>
            <li>présenter votre entreprise, son histoire, ses objectifs</li>
            <li>mettre en avant votre savoir-faire, vos produits ou créations</li>
            <li>valoriser votre marque par du contenu (brand content)</li>
          </ul>
        </section>
        <section className={styles.pres2}>
          <h1 className={styles.title}>Racontons une histoire</h1>
          <p>
            Afin de rendre votre vidéo captivante, j&#39;accorde de l&#39;importance à l&#39;élaboration du storytelling.
            Transmettre des émotions et du savoir, inviter à la réflexion. Voilà ce qui m&#39;anime !<br/>
            <Link to="/about" className={styles.link}>❯ À propos de moi</Link>
          </p>
          <p>
            Je vous accompagne de la conception à la réalisation.<br/>
            <Link to="/contact" className={styles.link}>❯ Discutons de votre projet</Link>
          </p>
        </section>
        <section className={styles.diagram}>
          <div className={styles.step}>
            <h3 className={styles.title}>Préproduction</h3>
            <ul>
              <li>Écoute de votre besoin</li>
              <li>Proposition créative</li>
              <li>Storyboard</li>
            </ul>
          </div>
          <div className={styles.step}>
            <h3 className={styles.title}>Production</h3>
            <ul>
              <li>Tournage</li>
              <li>Direction d'interview</li>
            </ul>
          </div>
          <div className={styles.step}>
            <h3 className={styles.title}>Postproduction</h3>
            <ul>
              <li>Montage</li>
              <li>Motion design</li>
              <li>Mixage / Étalonnage</li>
            </ul>
          </div>
        </section>
      </div>
    );
}

export default Services;
